import classNames from "classnames";
import { useMemo, useState } from "react";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { BaseClickableIcon } from "../../../../components/Icon/BaseClickableIcon.tsx";
import { useClickOutside } from "../../../../hooks/useOnClickOutside.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { FAVORITE_TAG_NAME } from "../../constants.ts";
import { useBoard } from "../../hooks/useBoard.ts";
import { tagsFilterStore } from "../../stores/tagsFilterStore.ts";
import type { Tag } from "../../types.ts";

export const TagsFilterBar = ({ tagsFilter }: { tagsFilter: Tag[] }) => {
  const { selectedTagsFilter } = tagsFilterStore.useState();
  const tagsAndSelectionInformation = useMemo(
    () =>
      tagsFilter
        // Set Favorite at the first position for display purpose
        .filter((tag) => tag.name === FAVORITE_TAG_NAME)
        .concat(tagsFilter.filter((tag) => tag.name !== FAVORITE_TAG_NAME))
        .map((tag) => ({
          isSelected: selectedTagsFilter.some((it) => it.uuid === tag.uuid),
          tag,
        })),
    [tagsFilter, selectedTagsFilter],
  );

  return (
    <div className="flex-row flex-fill gap-200 items-center">
      {/* FIXME: Define and use secondary button instead*/}
      <BaseTagFilterButton
        name="All assets"
        onClick={() => {
          tagsFilterStore.resetTagsFilter();
        }}
        isSelected={selectedTagsFilter.length === 0}
      />
      {tagsAndSelectionInformation.map((tag) => (
        <TagFilterButton
          key={tag.tag.uuid}
          tag={tag.tag}
          onClick={() => {
            if (!tag.isSelected) {
              tagsFilterStore.resetTagsFilter();
              tagsFilterStore.addTagToFilter(tag.tag);
            }
          }}
          onDeletionSuccess={() => {
            if (tag.isSelected) tagsFilterStore.resetTagsFilter();
          }}
          isSelected={tag.isSelected}
        />
      ))}
    </div>
  );
};

const TagFilterButton = ({
  tag,
  isSelected,
  onClick,
  onDeletionSuccess,
}: {
  tag: Tag;
  isSelected: boolean;
  onClick: () => void;
  onDeletionSuccess: () => void;
}) => {
  const { board } = useBoard();
  const deleteTag = useAppMutation({
    path: "tags/delete",
    invalidate: [`boards/${board.uuid}`],
    onSuccess: () => {
      onDeletionSuccess();
    },
  }).mutation;
  const [isDeletionConfirmationOpen, setIsDeletionConfirmationOpen] =
    useState<boolean>(false);
  const ref = useClickOutside(() => setIsDeletionConfirmationOpen(false));

  if (tag.name === FAVORITE_TAG_NAME) {
    return (
      <BaseTagFilterButton
        name="Favorites"
        isSelected={isSelected}
        onClick={onClick}
      />
    );
  }
  return (
    <div ref={ref} className="flex-row relative group">
      {isDeletionConfirmationOpen ? (
        <BaseButton
          className="px-200 py-100 rounded-100 text-center text-error-rest label-md-semibold border border-error-rest hover:opacity-80"
          disabled={deleteTag.isLoading}
          onClick={() => {
            deleteTag.mutate({ uuid: tag.uuid });
          }}
        >
          Confirm tag deletion
        </BaseButton>
      ) : (
        <>
          <BaseTagFilterButton
            name={tag.name}
            isSelected={isSelected}
            onClick={onClick}
          />
          <BaseClickableIcon
            name="X"
            iconClassName="rounded-full bg-surface-primary-rest"
            className={classNames(
              "!p-0 invisible absolute -top-150 -right-150 ",
              isSelected ? "group-hover:visible" : "",
            )}
            tooltip={{
              side: "top",
              content: "Delete tag",
            }}
            onClick={() => {
              setIsDeletionConfirmationOpen(true);
            }}
            size="sm"
          />
        </>
      )}
    </div>
  );
};

const BaseTagFilterButton = ({
  name,
  isSelected,
  onClick,
}: {
  name: string;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <BaseButton
    className={classNames(
      "px-200 py-100 rounded-100 text-center text-primary label-md-semibold",
      isSelected
        ? "bg-surface-primary-active"
        : "hover:bg-surface-primary-hover",
    )}
    onClick={onClick}
  >
    {name}
  </BaseButton>
);
