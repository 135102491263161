import type { StyleTransferInitImageInfluence } from "./types.ts";

export const INIT_IMAGE_COLOR_INFLUENCES: StyleTransferInitImageInfluence[] = [
  "black_and_white",
  "color",
];

export const initImageColorInfluenceLabelMapping: {
  [K in StyleTransferInitImageInfluence]: string;
} = {
  black_and_white: "Sketch",
  color: "Picture",
};
