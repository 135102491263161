import { usePersistedState } from "../../../../../../../hooks/usePersistedState.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import type {
  MLModelArchitecture,
  QualityPreset,
} from "../../../../../types.ts";
import type { StyleTransferInitImageInfluence } from "../types.ts";

export type StyleTransferSettings = {
  num_generations: number;
  colors_strength: number;
  structure_strength: number;
  creativity: number;
  negative_prompt: string;
  init_image_color_influence: StyleTransferInitImageInfluence;
  quality_preset: QualityPreset;
  ml_model_architecture: MLModelArchitecture | undefined;
  enable_flux_prompt_enhancement: boolean;
};

export const STYLE_TRANSFER_DEFAULT_SETTINGS = {
  num_generations: 4,
  colors_strength: 0.5,
  structure_strength: 0.5,
  creativity: 0.5,
  negative_prompt: "",
  init_image_color_influence: "black_and_white",
  quality_preset: "fast",
  ml_model_architecture: undefined,
  enable_flux_prompt_enhancement: false,
} as const;

export const useStyleTransferSettings = () => {
  const { board } = useBoard();
  const [styleTransferSettings, setStyleTransferSettings] =
    usePersistedState<StyleTransferSettings>({
      key: `style-transfer-settings-${board.uuid}-v8`,
      defaultValue: STYLE_TRANSFER_DEFAULT_SETTINGS,
    });

  return {
    styleTransferSettings,
    setStyleTransferSettings: (newSettings: Partial<StyleTransferSettings>) => {
      setStyleTransferSettings((settings) => ({
        ...settings,
        ...newSettings,
      }));
    },
  } as {
    styleTransferSettings: StyleTransferSettings;
    setStyleTransferSettings: (
      newSettings: Partial<StyleTransferSettings>,
    ) => void;
  };
};
