import { useState } from "react";
import { useOnMount } from "../../hooks/useOnMount.ts";
import type { Style } from "../types.ts";
import { FORM_INPUT_WIDTH_CSS } from "./constants.ts";
import { StyleImagesKindForm } from "./CreationPage/StyleImagesKindForm.tsx";
import { StyleInitSplashScreen } from "./CreationPage/StyleInitSplashScreen.tsx";
import { StyleNameForm } from "./CreationPage/StyleNameForm.tsx";
import { TrainingImagesForm } from "./CreationPage/TrainingImagesForm.tsx";
import { StyleDescription } from "./StyleDescription.tsx";
import { StyleTrainingPurchaseDialog } from "./StyleTrainingPurchaseDialog/StyleTrainingPurchaseDialog.tsx";
import type { StyleCreationStep } from "./types.ts";

export const StyleCreation = ({ style }: { style: Style }) => {
  const [currentStyleCreationStep, setCurrentStyleCreationStep] =
    useState<StyleCreationStep>("styleTrainingImagesForm");

  const [
    isTrainingStylePurchaseDialogOpen,
    setIsTrainingStylePurchaseDialogOpen,
  ] = useState(false);

  useOnMount(() => {
    //  XXX: If we start again the onboarding we set the step to the latest step that was reached
    if (style.status === "ready") {
      setCurrentStyleCreationStep("styleDescription");
    } else if (style.status === "training") {
      setCurrentStyleCreationStep("styleInitSplashScreen");
    } else if (style.status === "created") {
      if (style.name?.length === 0 || style.name === null) {
        setCurrentStyleCreationStep("styleTrainingImagesForm");
      } else if (style.images_kind === null) {
        setCurrentStyleCreationStep("styleImagesKindForm");
      } else {
        setCurrentStyleCreationStep("styleImagesKindForm");
      }
    }
  });

  switch (currentStyleCreationStep) {
    case "styleTrainingImagesForm":
      return (
        <TrainingImagesForm
          style={style}
          widthClassName={FORM_INPUT_WIDTH_CSS}
          onClickNext={() => {
            setCurrentStyleCreationStep("styleNameForm");
          }}
        />
      );
    case "styleNameForm":
      return (
        <StyleNameForm
          widthClassName={FORM_INPUT_WIDTH_CSS}
          style={style}
          onClickPrevious={() =>
            setCurrentStyleCreationStep("styleTrainingImagesForm")
          }
          onClickNext={() => setCurrentStyleCreationStep("styleImagesKindForm")}
        />
      );
    case "styleImagesKindForm":
      return (
        <>
          <StyleImagesKindForm
            style={style}
            onClickNext={() => {
              setIsTrainingStylePurchaseDialogOpen(true);
            }}
            onClickPrevious={() => {
              setCurrentStyleCreationStep("styleNameForm");
            }}
          />
          <StyleTrainingPurchaseDialog
            isOpen={isTrainingStylePurchaseDialogOpen}
            onOpenChange={setIsTrainingStylePurchaseDialogOpen}
            style={style}
            onTrainStyleSuccess={() =>
              setCurrentStyleCreationStep("styleInitSplashScreen")
            }
          />
        </>
      );
    case "styleInitSplashScreen":
      return <StyleInitSplashScreen widthClassName={FORM_INPUT_WIDTH_CSS} />;
    case "styleDescription":
      return <StyleDescription style={style} />;
  }
};
