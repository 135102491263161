import { usePersistedState } from "../../../../../../../hooks/usePersistedState.ts";
import { useAppQuery } from "../../../../../../../http/useAppQuery.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import type {
  MLModelArchitecture,
  QualityPreset,
} from "../../../../../types.ts";
import type {
  VariationCreativityStrengthPreset,
  VariationCreativityStrengthPresets,
} from "../types.ts";

export type VariationSettings = {
  num_generations: number;
  prompt_strength: number;
  negative_prompt: string;
  quality_preset: QualityPreset;
  creativity_strength_preset: VariationCreativityStrengthPreset;
  ml_model_architecture: MLModelArchitecture | undefined;
  guidance_scale: number;
  enable_flux_prompt_enhancement: boolean;
};

export const VARIATION_DEFAULT_SETTINGS = {
  num_generations: 4,
  prompt_strength: 0.5,
  negative_prompt: "",
  creativity_strength_preset: "medium",
  quality_preset: "fast",
  ml_model_architecture: undefined,
  guidance_scale: 0.5,
  enable_flux_prompt_enhancement: true,
} as const;

export const useVariationSettings = () => {
  const { board } = useBoard();
  const [variationSettings, setVariationSettings] =
    usePersistedState<VariationSettings>({
      key: `variation-settings-${board.uuid}-v8`,
      defaultValue: VARIATION_DEFAULT_SETTINGS,
    });

  const { data: creativityStrengthPreset } =
    useAppQuery<VariationCreativityStrengthPresets>({
      queryKey: "presets/variation",
    });

  return {
    variationSettings,
    setVariationSettings: (newSettings: Partial<VariationSettings>) => {
      let creativityStrengthPresetSettings: Partial<VariationSettings> = {};
      if (newSettings.creativity_strength_preset && creativityStrengthPreset) {
        // XXX: remove presets equal to null for custom presets
        creativityStrengthPresetSettings = Object.fromEntries(
          Object.entries(
            creativityStrengthPreset[newSettings.creativity_strength_preset],
          ).filter(([_, v]) => v !== null),
        );
      }
      setVariationSettings((settings) => ({
        ...settings,
        ...newSettings,
        ...creativityStrengthPresetSettings,
      }));
    },
  } as {
    variationSettings: VariationSettings;
    setVariationSettings: (newSettings: Partial<VariationSettings>) => void;
  };
};
