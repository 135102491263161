import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { useSelectedStylesGenerationParamsStore } from "../../../Board/hooks/useSelectedStylesGenerationParams.ts";

export const useCreateBoard = ({
  onSuccess,
}: {
  onSuccess?: (uuid: string) => void;
}) => {
  const { mutate, isLoading } = useAppMutation({
    path: "boards",
    invalidate: ["boards"],
    waitForInvalidation: false,
  }).mutation;

  return {
    createBoard: ({
      styleUuid,
      name = "",
    }: {
      styleUuid?: string;
      name?: string;
    } = {}) => {
      mutate(
        { name },
        {
          onSuccess: ({ data }) => {
            if (styleUuid) {
              useSelectedStylesGenerationParamsStore.resetStyleSelections({
                boardUuid: data.uuid,
              });
              useSelectedStylesGenerationParamsStore.addStyleToSelection({
                boardUuid: data.uuid as string,
                styleUuid,
              });
            }
            onSuccess?.(data.uuid as string);
          },
        },
      );
    },
    isCreateBoardLoading: isLoading,
  };
};
