import classNames from "classnames";
import type { ReactNode } from "react";
import { useImageDropZone } from "../../../../hooks/useImageDropZone.ts";

export const ImageDropZone = ({
  onDrop,
  content,
  className,
  minImageSideDimension = 256,
}: {
  onDrop: (file: { fileToUpload: File; uuid: string } | undefined) => void;
  content: ReactNode;
  className?: string;
  minImageSideDimension?: number;
}) => {
  const {
    getRootProps,
    getInputProps,
    open: openFileBrowser,
  } = useImageDropZone({
    multiple: false,
    minImageSize: minImageSideDimension,
    onImageDrop: onDrop,
  });

  return (
    <div {...getRootProps()} className="h-full w-full">
      <input {...getInputProps()} />
      <button
        type="button"
        className={classNames(
          "h-full w-full border border-dashed border-emphasis-rest",
          className,
        )}
        onClick={() => openFileBrowser()}
      >
        <div className="flex-col-center gap-1000 max-h-[250px]">
          <input {...getInputProps()} />
          {content}
        </div>
      </button>
    </div>
  );
};
