import classNames from "classnames";
import { useState } from "react";
import { ClickableIcon } from "../../../components/Icon/ClickableIcon.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import {
  CDNImage,
  type SrcDimension,
} from "../../../components/Image/CDNImage.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { Progress } from "../../../components/Progress/Progress.tsx";
import { useOnMount } from "../../../hooks/useOnMount.ts";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import { TOOL_TRACKING_NAMES } from "../constants.ts";
import { useUploadImage } from "../hooks/useUploadImage.ts";
import type { ImageContent } from "../types.ts";
import { getSelectedTool } from "../utils/getSelectedTool.ts";
import { ImageDropZone } from "./ImageDropZone/ImageDropZone.tsx";

export const ImagePicker = ({
  imageUuid,
  onImageUuidChange,
  isError,
  className,
  srcDimension,
}: {
  imageUuid?: string;
  onImageUuidChange: (uuid: string | undefined) => void;
  isError?: boolean;
  className?: string;
  srcDimension: SrcDimension;
}) => {
  const { data: image } = useAppQuery<ImageContent>({
    queryKey: imageUuid ? `contents/${imageUuid}` : null,
  });

  const [uploadingImage, setUploadingImage] = useState<{
    fileToUpload: File;
    uuid: string;
  }>();

  return (
    <div
      className={classNames(
        "h-[100px] w-full bg-input-surface-rest",
        className,
      )}
    >
      {uploadingImage === undefined && imageUuid === undefined ? (
        <ImageDropZone
          className={classNames(
            "h-full",
            isError
              ? "!bg-surface-error-secondary-rest !border-error-rest"
              : "",
          )}
          onDrop={setUploadingImage}
          content={
            <Icon
              name="ImagePlus"
              size="lg"
              className={isError ? "stroke-error-rest" : "stroke-emphasis-rest"}
            />
          }
        />
      ) : (
        <div className="relative h-full flex-row-center">
          {uploadingImage !== undefined ? (
            <InitialLocalImage
              file={uploadingImage.fileToUpload}
              onSuccess={(uuid) => {
                onImageUuidChange(uuid);
              }}
            />
          ) : (
            <CDNImage
              className="h-full w-full flex-row-center"
              imageClassName="h-full max-w-full object-contain"
              src={image?.url}
              srcDimension={srcDimension}
            />
          )}
          {imageUuid && (
            <ClickableIcon
              name="X"
              size="sm"
              variant="tertiary"
              className="absolute right-0 top-0"
              onClick={() => {
                onImageUuidChange(undefined);
                setUploadingImage(undefined);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const InitialLocalImage = ({
  file,
  onSuccess,
}: {
  file: File;
  onSuccess?: (image_uuid: string) => void;
}) => {
  const [src] = useState(() => URL.createObjectURL(file));
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const {
    mutate: uploadImage,
    progress,
    isLoading: IsUploadImageLoading,
  } = useUploadImage({
    describeImage: true,
    onSuccess: (imageUuid) => {
      onSuccess?.(imageUuid);
    },
    maxSize: 1024,
  });

  const tool = getSelectedTool();

  useOnMount(() => {
    uploadImage({ image: file as Blob });
    trackEvent(
      "content:upload",
      tool ? { generation_type: TOOL_TRACKING_NAMES[tool] } : {},
    );
  });

  return (
    <div className="h-full w-full relative flex-row-center">
      <Image
        src={src}
        className="h-full w-full flex-row-center"
        imageClassName="h-full object-contain object-center"
        onLoad={() => setIsImageLoaded(true)}
      />
      {isImageLoaded && IsUploadImageLoading && (
        <Progress
          value={progress}
          rootClassName="w-[30px] absolute !bg-surface-primary-hover rounded-200"
        />
      )}
    </div>
  );
};
