import type { Style } from "../Home/HomeIndex/types.ts";

export const countNumberOfOccurencesPerStyleTags = (styles: Style[]) =>
  styles
    .flatMapNotNull((style) => style.tags)
    .reduce<Record<string, number | undefined>>((accumulator, value) => {
      accumulator[value] =
        accumulator[value] !== undefined ? accumulator[value] + 1 : 1;
      return accumulator;
    }, {});
