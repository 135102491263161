import classNames from "classnames";
import {
  type ButtonHTMLAttributes,
  forwardRef,
  type ReactNode,
  type Ref,
} from "react";
import { Icon, type IconName } from "../Icon/Icon.tsx";
import { Spinner } from "../Spinner/Spinner.tsx";
import { BaseButton } from "./BaseButton.tsx";
import styles from "./button.module.css";

export type ButtonSize = "sm" | "md" | "lg";
export type ButtonVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "inverse-primary";

export type ButtonProps = {
  children?: ReactNode;
  variant?: ButtonVariant;
  loading?: boolean;
  loadingContent?: ReactNode;
  size?: ButtonSize;
  disclosure?: boolean;
  iconName?: IconName;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef(
  (
    {
      children,
      loading,
      loadingContent,
      disabled,
      variant = "primary",
      type = "button",
      size = "lg",
      iconName,
      className,
      disclosure = false,
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <BaseButton
      ref={ref}
      className={classNames(styles[variant], styles[size], className)}
      type={type}
      disabled={!!disabled || loading}
      loading={loading}
      loadingContent={loadingContent ? loadingContent : <Spinner size={size} />}
      children={
        <>
          {iconName && (
            <Icon name={iconName} size={size !== "lg" ? "sm" : "md"} />
          )}
          {children}
          {disclosure ? <Icon name="ChevronDown" size="xs" /> : null}
        </>
      }
      {...rest}
    />
  ),
);
