import { useMemo } from "react";
import { BaseClickableIcon } from "../../../../../components/Icon/BaseClickableIcon.tsx";
import { useAppMutation } from "../../../../../http/useAppMutation.ts";
import { FAVORITE_TAG_NAME } from "../../../constants.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import { useDeleteAssetOnBoard } from "../../../hooks/useDeleteAssetOnBoard.ts";
import { ImageDownloadButton } from "../../../ImageDownload/ImageDownloadButton.tsx";
import type { Asset } from "../../../types.ts";

export const AssetActions = ({ asset }: { asset: Asset }) => {
  const { board } = useBoard();
  const favoriteTag = useMemo(
    () => board.tags.find((it) => it.name === FAVORITE_TAG_NAME),
    [board.tags],
  );
  const addTag = useAppMutation({
    path: "tags/add-assets",
    invalidate: [`assets/${asset.uuid}`, `boards/${board.uuid}`],
  }).mutation;
  const removeTag = useAppMutation({
    path: "tags/remove-assets",
    invalidate: [`assets/${asset.uuid}`, `boards/${board.uuid}`],
  }).mutation;

  const addOrRemoveFavoriteTag = () => {
    if (!favoriteTag) return;
    if (asset.is_in_favorite) {
      removeTag.mutate({
        asset_uuids: [asset.uuid],
        tag_uuid: favoriteTag.uuid,
      });
    } else {
      addTag.mutate({
        asset_uuids: [asset.uuid],
        tag_uuid: favoriteTag.uuid,
      });
    }
  };

  const { mutate: deleteAssetMutation, isLoading: deleteAssetIsLoading } =
    useDeleteAssetOnBoard();

  return (
    <div className="h-800 flex-row-center gap-200">
      {favoriteTag && (
        <BaseClickableIcon
          name="Heart"
          iconClassName={
            asset.is_in_favorite ? "fill-error-rest stroke-error-rest" : ""
          }
          tooltip={{
            side: "bottom",
            content: asset.is_in_favorite
              ? "Remove from favorites"
              : "Add to favorites",
          }}
          onClick={() => {
            addOrRemoveFavoriteTag();
          }}
          className="p-200 rounded-100 hover:bg-surface-primary-hover"
        />
      )}
      <div className="h-[75%] border-l" />
      <BaseClickableIcon
        name="Trash"
        tooltip={{ side: "bottom", content: "Delete" }}
        disabled={deleteAssetIsLoading || asset.deleted_at !== null}
        onClick={() => {
          deleteAssetMutation({
            uuids: [asset.uuid],
          });
        }}
        className="p-200 rounded-100 hover:bg-surface-primary-hover"
      />
      <ImageDownloadButton asset={asset} />
    </div>
  );
};
