export const HOME = "/home";
export const ADMIN = "/admin";
export const PURCHASES = "purchases";

export const BOARDS = "/boards";
export const STYLES = "/styles";

export const PAYMENT_CONFIRMATION = "/payment/confirmation";

// Board - Workspaces
export const CREATE_WORKSPACE = "create";
export const EDIT_WORKSPACE = "edit";

// Board - Tools
export const GENERATE_TOOL = "generate";
export const GENERATE_TOOL_PATH =
  `${CREATE_WORKSPACE}/${GENERATE_TOOL}` as const;
export const TRANSFER_TOOL = "transfer";
export const TRANSFER_TOOL_PATH =
  `${CREATE_WORKSPACE}/${TRANSFER_TOOL}` as const;

export const VARIATION_TOOL = "variation";
export const VARIATION_TOOL_PATH =
  `${EDIT_WORKSPACE}/${VARIATION_TOOL}` as const;
export const SELECT_TOOL = "select";
export const SELECT_TOOL_PATH = `${EDIT_WORKSPACE}/${SELECT_TOOL}` as const;
export const UPSCALE_TOOL = "upscale";
export const UPSCALE_TOOL_PATH = `${EDIT_WORKSPACE}/${UPSCALE_TOOL}` as const;
