import * as SliderPrimitive from "@radix-ui/react-slider";
import classNames from "classnames";
import { run } from "../../utils/common.ts";
import { InputNumber } from "../InputNumber/InputNumber.tsx";

export const Slider = ({
  min,
  max,
  value,
  markValue,
  onChange,
  className,
  markValueClassName,
  displayPercentage,
  displayNumber = true,
}: {
  min: number;
  max: number;
  value: number;
  markValue?: number;
  onChange: (value: number) => void;
  className?: string;
  markValueClassName?: string;
  displayPercentage?: boolean;
  displayNumber?: boolean;
}) => {
  const thumbSize = 16;

  // XXX: we compute the same offset for the mark and the thumb, so they are aligned
  // https://github.com/radix-ui/primitives/blob/b32a93318cdfce383c2eec095710d35ffbd33a1c/packages/react/slider/src/Slider.tsx#L706
  const mark = run(() => {
    if (!markValue) return;
    const percentPosition = (markValue - min) / (max - min);
    return { offset: thumbSize * (percentPosition - 0.5), percentPosition };
  });

  return (
    <div
      className={classNames("flex-row h-800 gap-100 items-center", className)}
    >
      <SliderPrimitive.Root
        className="flex-fill relative h-025 flex touch-none select-none items-center rounded-200 bg-surface-secondary-active cursor-pointer"
        min={min}
        max={max}
        value={[value]}
        onValueChange={(values) => onChange(values[0])}
      >
        <SliderPrimitive.Track className="relative h-100 w-full grow rounded-[inherit] bg-[inherit]">
          <SliderPrimitive.Range className="absolute h-full rounded-[inherit] bg-surface-inverse-rest" />
          {/* FIXME: Once final slider design validated remove mark if not required.*/}
          {mark && (
            <div
              className={classNames(
                "absolute -top-050 border-l-050 h-200 rounded-100 border-emphasis-rest",
                markValueClassName,
              )}
              style={{
                left: `calc(${mark.percentPosition * 100}% - ${mark.offset}px)`,
              }}
            />
          )}
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
          className="block border-050 border-[#ffffff] rounded-full bg-surface-inverse-rest shadow-100"
          style={{
            width: thumbSize,
            height: thumbSize,
          }}
        />
      </SliderPrimitive.Root>
      {/* FIXME: Below section to remove once full design over, we keep it only for compatibility with current parameters menu*/}
      {displayNumber && (
        <div className="flex-row bg-surface-transparent-primary-rest text-primary label-md-default">
          <InputNumber
            min={min}
            max={max}
            value={value}
            className="hide-controls text-end bg-surface-transparent-primary-rest w-[22px]"
            onChange={onChange}
          />
          {displayPercentage && <span>%</span>}
        </div>
      )}
    </div>
  );
};
