import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { useSelectedStylesGenerationParams } from "../../../../../hooks/useSelectedStylesGenerationParams.ts";
import { useUploadImage } from "../../../../../hooks/useUploadImage.ts";
import { useGenerativeFillSettings } from "./useGenerativeFillSettings.ts";

export const useUploadAndGenerativeFillMutation = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  // XXX: We use refs to store user inputs and be able to use them in the second mutation (generation mutation) called
  // on successful upload.
  // FIXME: Replace this double mutation by a single chain of async mutations for better readability
  const generationPrompt = useRef("");
  const generationAssetUuid = useRef<string>();

  const { mutate: generativeFill, isLoading: isGenerativeFillLoading } =
    useGenerativeFill({
      onSuccess: () => {
        onSuccess?.();
      },
    });

  const { mutate: upload, isLoading: isUploading } = useUploadImage({
    onSuccess: (imageUuid) => {
      if (generationPrompt.current && generationAssetUuid.current) {
        generativeFill({
          prompt: generationPrompt.current,
          assetUuid: generationAssetUuid.current,
          maskUuid: imageUuid,
        });
      }
    },
  });

  return {
    mutate: ({
      mask,
      prompt,
      assetUuid,
    }: {
      mask: Blob;
      prompt: string;
      assetUuid: string;
    }) => {
      generationPrompt.current = prompt;
      generationAssetUuid.current = assetUuid;
      upload({ image: mask });
    },
    isLoading: isGenerativeFillLoading || isUploading,
  };
};

const useGenerativeFill = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { board } = useBoard();
  const {
    mutation: { mutate, isLoading },
  } = useAppMutation({
    path: "boards/add-element-on-image",
    invalidate: [`boards/${board.uuid}`, "boards", "users/me"],

    onSuccess: () => {
      onSuccess?.();
    },
  });

  const { generativeFillSettings } = useGenerativeFillSettings();
  const { selectedStylesGenerationParams } =
    useSelectedStylesGenerationParams();

  const queryClient = useQueryClient();

  return {
    mutate: ({
      maskUuid,
      prompt,
      assetUuid,
    }: {
      maskUuid: string;
      prompt: string;
      assetUuid: string;
    }) => {
      if (!selectedStylesGenerationParams.length) return;

      mutate(
        {
          prompt,
          board_uuid: board.uuid,
          style_uuid: selectedStylesGenerationParams[0].uuid,
          asset_uuid: assetUuid,
          mask_uuid: maskUuid,
          num_generations: generativeFillSettings.num_generations,
          prompt_strength:
            generativeFillSettings.creativity_strength_preset === "custom"
              ? generativeFillSettings.prompt_strength
              : undefined,
          lora_scale: selectedStylesGenerationParams[0].scale,
          negative_prompt: generativeFillSettings.negative_prompt,
          padding_mask_crop: generativeFillSettings.padding_mask_crop,
          auto_patch: generativeFillSettings.auto_patch,
          creativity_strength_preset:
            generativeFillSettings.creativity_strength_preset,
          quality_preset: generativeFillSettings.quality_preset,
          ml_model_architecture: generativeFillSettings.ml_model_architecture,
          guidance_scale: generativeFillSettings.guidance_scale,
        },
        {
          onSuccess: () => {
            [`assets/${assetUuid}`, `assets/${assetUuid}/history`].map(
              (queryKey) =>
                void queryClient.invalidateQueries({ queryKey: [queryKey] }),
            );
          },
        },
      );
    },
    isLoading,
  };
};
