import { useEffect, useMemo } from "react";
import { z } from "zod";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { createStorageStore } from "../../../utils/createStore.ts";
import type { Style } from "../../Home/HomeIndex/types.ts";
import { getSelectedTool } from "../utils/getSelectedTool.ts";
import { useBoard } from "./useBoard.ts";

// FIXME: Adapt lora scale value per tool if required
export const DEFAULT_LORA_SCALE = 0.5;

const zStylesGenerationParams = z.array(
  z.object({
    uuid: z.string(),
    scale: z.number(),
  }),
);

export type StylesGenerationParams = z.infer<typeof zStylesGenerationParams>;

export const useSelectedStylesGenerationParamsStore = createStorageStore(
  localStorage,
  "board-selected-styles-generation-params-v0",
  z.record(zStylesGenerationParams.optional()),
  {},
  ({ update }) => ({
    addStyleToSelection: ({
      boardUuid,
      styleUuid,
    }: {
      boardUuid: string;
      styleUuid: string;
    }) =>
      update((draft) => {
        const currentStyles = draft[boardUuid]!.filter(
          (it) => it.uuid !== styleUuid,
        );
        draft[boardUuid] = [
          ...currentStyles,
          {
            uuid: styleUuid,
            scale: DEFAULT_LORA_SCALE,
          },
        ];
      }),
    updateStyleLoraScale: ({
      boardUuid,
      styleUuid,
      scale,
    }: {
      boardUuid: string;
      styleUuid: string;
      scale: number;
    }) =>
      update((draft) => {
        const currentStyles = draft[boardUuid]!.filter(
          (it) => it.uuid !== styleUuid,
        );
        draft[boardUuid] = [
          ...currentStyles,
          {
            uuid: styleUuid,
            scale,
          },
        ];
      }),
    removeStyleFromSelection: ({
      boardUuid,
      styleUuid,
    }: {
      boardUuid: string;
      styleUuid: string;
    }) =>
      update((draft) => {
        const currentStyles = draft[boardUuid]!.filter(
          (it) => it.uuid !== styleUuid,
        );
        draft[boardUuid] = [...currentStyles];
      }),
    resetStyleSelections: ({ boardUuid }: { boardUuid: string }) =>
      update((draft) => {
        draft[boardUuid] = [];
      }),
  }),
);

export const useSelectedStylesGenerationParams = () => {
  const { board } = useBoard();
  const selectedStylesGenerationParams =
    useSelectedStylesGenerationParamsStore.useState((s) => s[board.uuid]);

  const { data: styles } = useAppQuery<Style[]>({
    queryKey: "styles",
  });

  const selectedStyles = useMemo(
    () =>
      styles?.filter(
        (style) =>
          selectedStylesGenerationParams
            ?.map((it) => it.uuid)
            .includes(style.uuid),
      ),
    [styles, selectedStylesGenerationParams],
  );

  useEffect(() => {
    if (selectedStylesGenerationParams === undefined) {
      useSelectedStylesGenerationParamsStore.resetStyleSelections({
        boardUuid: board.uuid,
      });
    }
  }, [selectedStylesGenerationParams, board.uuid]);

  const tool = getSelectedTool();

  return {
    selectedStylesGenerationParams:
      useSelectedStylesGenerationParamsStore.useState((s) => s[board.uuid]) ??
      [],
    selectedStyles: selectedStyles ?? [],
    isMixCompatibleWithCurrentTool: tool === "generate",
    areSelectedStylesMixable:
      ((selectedStylesGenerationParams?.length ?? 0) < 2 ||
        selectedStyles?.every((it) => it.is_mixable)) ??
      true,
  };
};
