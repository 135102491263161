import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button.tsx";
import { Pimento } from "../../components/Icon/assets/Pimento.tsx";
import { PimentoStar } from "../../components/Icon/assets/PimentoStar.tsx";
import { BOARDS } from "../../routes.ts";
import { trackConversionEvent } from "../../utils/google_ads.ts";
import { getReturnPath } from "../../utils/returnPath.ts";

export const PaymentConfirmationView = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const price = urlParams.get("price");
  const item = urlParams.get("item");
  const transactionId = urlParams.get("transaction_id");

  useEffect(() => {
    if (price && item && transactionId) {
      trackConversionEvent("purchase", {
        value: price,
        transaction_id: transactionId,
        currency: "USD",
        items: [
          {
            item_name: item,
          },
        ],
      });
    }
  }, [item, price, transactionId]);

  return (
    <div className="relative h-full w-full bg-inverse content-center">
      <PimentoStar
        size={600}
        className="absolute -bottom-[260px] -left-[260px] fill-surface-caution-primary-rest"
      />
      <PimentoStar
        size={600}
        className="absolute -top-[260px] -right-[260px] fill-surface-caution-primary-rest"
      />
      <div className="relative flex-col h-full justify-center items-center">
        <Pimento size={90} className="absolute top-1200 fill-white" />
        <div className="flex-col w-[560px] items-center gap-800">
          <div className="flex-col items-center gap-400 text-inverse-secondary">
            <div className="heading-2xl">You've made a great choice</div>
            <div className="body-lg-semibold text-center">
              Congratulations on joining Pimento! Dive in and start creating
              with the full experience at your fingertips.
            </div>
          </div>
          <Button
            variant="inverse-primary"
            size="lg"
            onClick={() => {
              const returnPath = getReturnPath();
              navigate(returnPath ? returnPath : BOARDS);
            }}
          >
            Start creating
          </Button>
        </div>
      </div>
    </div>
  );
};
