import classNames from "classnames";
import { useState } from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { PimentoStar } from "../../../components/Icon/assets/PimentoStar.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Popover } from "../../../components/Popover/Popover.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { CREATE_WORKSPACE, EDIT_WORKSPACE } from "../../../routes.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import { ToolMenuNavigation } from "../Workspaces/components/ToolMenu/ToolMenuButton.tsx";
import { CreateToolMenu } from "./CreateToolMenu.tsx";
import { EditToolMenu } from "./EditToolMenu.tsx";

export const ToolMenu = () => (
  <div className="h-full w-[65px] px-100 pt-050 pb-200 flex-col border-r">
    <ToolMenuNavigation to="/" title="Home" iconName="PimentoStar" />
    <div className="flex-fill flex-col-center">
      <Routes>
        <Route path={`${CREATE_WORKSPACE}/*`} element={<CreateToolMenu />} />
        <Route path={`${EDIT_WORKSPACE}/*`} element={<EditToolMenu />} />
      </Routes>
    </div>
    <CreditsCounter />
  </div>
);

const CreditsCounter = () => {
  const { user } = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  if (!user) return null;

  return (
    <Popover
      isOpen={isMenuOpen}
      onOpenChange={setIsMenuOpen}
      className="!bg-surface-inverse-rest"
      side="right"
      align="end"
      content={
        <div className="flex-row gap-400 p-400 items-center">
          <div className="flex-col-center h-1000 aspect-square bg-surface-emphasis-primary-rest rounded-full">
            <Icon name="PimentoStar" className="fill-button-primary-rest" />
          </div>
          <div className="flex-col gap-100">
            <div className="label-lg-semibold text-white">
              {user.total_credits_remaining_amount} credits left
            </div>
            {!isUserAllowedTo(user, "subscription:upgrade:hidden") && (
              <button
                type="button"
                className="text-emphasis-secondary label-lg-semibold underline"
                onClick={() => {
                  trackEvent("upgrade:display_popup", {
                    reason: "remaining_credits_click",
                  });
                  setIsMenuOpen(false);
                  upgradeDialogStore.openDialog({ isClosable: true });
                }}
              >
                Upgrade now!
              </button>
            )}
          </div>
        </div>
      }
    >
      <BaseButton
        onClick={() => setIsMenuOpen(true)}
        className={classNames(
          "px-100 py-050 flex-row items-center gap-100 rounded-600 bg-surface-emphasis-primary-rest",
          isMenuOpen && "opacity-80",
        )}
      >
        <PimentoStar size={10} className="fill-button-primary-rest" />
        <span
          className={classNames(
            "flex-fill text-center text-white label-md-defaults",
            user.total_credits_remaining_amount >= 10000
              ? "text-[10px]"
              : "text-[12px]",
          )}
        >
          {user.total_credits_remaining_amount}
        </span>
      </BaseButton>
    </Popover>
  );
};
