import classNames from "classnames";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { CDNImage } from "../../../components/Image/CDNImage.tsx";
import { GENERIC_STYLE_UUID } from "../../constants.ts";
import type { Style } from "../../Home/HomeIndex/types.ts";

export const StyleSecondaryNavigation = ({
  styles,
  selectedStyleUuid,
  onStyleUuidSelect,
}: {
  styles: Style[];
  selectedStyleUuid: string | undefined;
  onStyleUuidSelect: (styleUuid: string) => void;
}) => (
  <div className="flex-col w-[240px] pt-300 border-r text-primary">
    <div className="flex-col px-300 flex-fill overflow-y-auto">
      {styles.map((style) => (
        <StylePickerSecondaryNavigationButton
          key={style.uuid}
          style={style}
          onClick={() => onStyleUuidSelect(style.uuid)}
          isActive={selectedStyleUuid === style.uuid}
        />
      ))}
    </div>
  </div>
);

const StylePickerSecondaryNavigationButton = ({
  onClick,
  isActive,
  style,
}: {
  onClick: () => void;
  isActive: boolean;
  style: Style;
}) => (
  <BaseButton onClick={onClick}>
    <div
      className={classNames(
        isActive
          ? "text-primary label-md-semibold bg-surface-primary-active"
          : "text-secondary label-md-default hover:bg-surface-primary-hover",
        "flex-row items-center h-800 gap-150 pl-150 pr-200 rounded-150",
      )}
    >
      <div className="w-500 h-500 rounded-100 overflow-clip border-input-border-width">
        {style.thumbnail_url ? (
          <CDNImage
            src={style.thumbnail_url}
            className="w-full h-full"
            imageClassName="h-full w-full object-cover object-center"
            srcDimension="thumbnail64"
          />
        ) : style.uuid === GENERIC_STYLE_UUID ? (
          <div className="w-full h-full flex-row-center bg-surface-emphasis-primary-rest">
            <Icon
              size="xs"
              name="PimentoStar"
              className="fill-button-primary-rest"
            />
          </div>
        ) : (
          <div className="flex-col-center h-full w-full bg-surface-secondary-rest" />
        )}
      </div>
      <div
        className={classNames(
          isActive
            ? "text-primary label-md-semibold"
            : "text-secondary label-md-semibold",
          "flex-fill truncate w-[70%]",
        )}
      >
        {style.name ? style.name : "Untitled"}
      </div>
      <div className="h-400 w-400" />
    </div>
  </BaseButton>
);
