import type { AxiosResponse } from "axios";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { useSelectedStylesGenerationParams } from "../../../../../hooks/useSelectedStylesGenerationParams.ts";
import { useStyleTransferSettings } from "./useStyleTransferSettings.ts";

export const useStyleTransferGeneration = ({
  onSuccess,
}: {
  onSuccess?: (
    response: AxiosResponse<{
      image_uuids: string[];
    }>,
  ) => void;
}) => {
  const { board } = useBoard();
  const { styleTransferSettings } = useStyleTransferSettings();

  const transferStyleMutation = useAppMutation<{
    image_uuids: string[];
  }>({
    path: "boards/transfer-style",
    invalidate: [`boards/${board.uuid}`, "boards", "users/me"],
    onSuccess,
  }).mutation;

  const { selectedStylesGenerationParams } =
    useSelectedStylesGenerationParams();

  return {
    mutation: ({
      prompt,
      initImageUuid,
    }: {
      prompt: string;
      initImageUuid: string;
    }) => {
      if (!selectedStylesGenerationParams.length) return;
      transferStyleMutation.mutate({
        board_uuid: board.uuid,
        prompt,
        style_uuid: selectedStylesGenerationParams[0].uuid,
        creativity: styleTransferSettings.creativity,
        lora_scale: selectedStylesGenerationParams[0].scale,
        init_image_uuid: initImageUuid,
        structure_strength: styleTransferSettings.structure_strength,
        colors_strength: styleTransferSettings.colors_strength,
        negative_prompt: styleTransferSettings.negative_prompt,
        init_image_color_influence:
          styleTransferSettings.init_image_color_influence,
        quality_preset: styleTransferSettings.quality_preset,
        ml_model_architecture: styleTransferSettings.ml_model_architecture,
      });
    },
    isLoading: transferStyleMutation.isLoading,
  };
};
