import classNames from "classnames";
import type { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Icon, type IconName } from "../../../../../components/Icon/Icon.tsx";
import { Tooltip } from "../../../../../components/Tooltip/Tooltip.tsx";
import { applyCurrentSearchParamsToPath } from "../../../../../utils/url.ts";
import { getSelectedTool } from "../../../utils/getSelectedTool.ts";

export const ToolMenuButton = ({
  children,
  tooltipContent,
}: {
  children: ReactNode;
  tooltipContent?: ReactNode;
}) => (
  <Tooltip
    side="right"
    align="start"
    arrowDisplayed
    delayDuration={500}
    content={tooltipContent}
    className="!bg-surface-inverse-rest !rounded-200"
  >
    {children}
  </Tooltip>
);

export const ToolMenuNavigation = ({
  to,
  iconName,
  title,
  tooltipContent,
  isBeta = false,
  onClick,
}: {
  to: string;
  iconName: IconName;
  title: string;
  tooltipContent?: ReactNode;
  isBeta?: boolean;
  onClick?: () => void;
}) => {
  const tool = getSelectedTool();

  return (
    <ToolMenuButton tooltipContent={tooltipContent}>
      <div>
        <NavLink
          to={applyCurrentSearchParamsToPath(tool === to ? "." : to)}
          className="py-200 group flex-col-center gap-200"
          onClick={onClick}
        >
          {({ isActive }) => (
            <>
              <div
                className={classNames(
                  "flex-col-center p-200 rounded-200",
                  isActive
                    ? "bg-surface-primary-active"
                    : "group-hover:bg-surface-primary-hover",
                )}
              >
                <Icon name={iconName} />
              </div>
              <div className="flex-col items-center text-primary">
                <div className="label-sm-semibold">{title}</div>
                {isBeta && (
                  <div className="text-emphasis-secondary label-md-semibold px-150 rounded-full bg-blue-100">
                    Beta
                  </div>
                )}
              </div>
            </>
          )}
        </NavLink>
      </div>
    </ToolMenuButton>
  );
};
