import classNames from "classnames";
import type { ReactNode } from "react";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { BaseDialog } from "../../../../components/Dialog/BaseDialog.tsx";
import { Icon } from "../../../../components/Icon/Icon.tsx";

export const DeletionDialog = ({
  dialogOpen,
  onDialogChange,
  onCancel,
  isLoading,
  onClick,
  children,
}: {
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onCancel: () => void;
  isLoading?: boolean;
  onClick: () => void;
  children: ReactNode;
}) => (
  <BaseDialog
    isOpen={dialogOpen}
    onOpenChange={onDialogChange}
    displayCloseButton={false}
    clickOutsideAuthorized
    className="sm:max-w-[500px] w-[400px] bg-surface-primary-rest p-800"
    content={
      <>
        <div className="mb-400">
          <Icon name="Trash" size="lg" />
        </div>
        {children}
        <div className="flex-row justify-between">
          <BaseButton
            onClick={() => onCancel()}
            disabled={isLoading}
            className="rounded-100 py-100 flex-row items-center"
          >
            <div className="text-primary hover:text-secondary label-lg-semibold underline">
              Cancel
            </div>
          </BaseButton>
          <BaseButton
            className={classNames(
              "rounded-100 border-050 min-w-[90px] min-h-1000 flex-row-center hover:bg-surface-error-primary-rest",
              isLoading ? "bg-surface-error-primary-rest" : "",
            )}
            loading={isLoading}
            onClick={onClick}
          >
            <div className="text-primary label-lg-semibold">Delete</div>
          </BaseButton>
        </div>
      </>
    }
  />
);
