import { Badge } from "../../../components/Badge/Badge.tsx";
import { Button } from "../../../components/Button/Button.tsx";
import { PimentoStar } from "../../../components/Icon/assets/PimentoStar.tsx";
import { CDNImage } from "../../../components/Image/CDNImage.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { useRetrainStyle } from "../../Board/hooks/useRetrainStyle.ts";
import { GENERIC_STYLE_UUID } from "../../constants.ts";
import type { Style } from "../../types.ts";
import { ModelDetails } from "../ModelDetails/ModelDetails.tsx";

export const StyleDescription = ({ styleUuid }: { styleUuid: string }) => {
  const { data: style } = useAppQuery<Style>({
    queryKey: `styles/${styleUuid}`,
  });

  const {
    retrain,
    isLoading,
    isDisabled: isRetrainDisabled,
    isUserAllowed,
    styleRetrainStatus,
  } = useRetrainStyle({ styleUuid });

  return (
    style && (
      <div className="flex-col flex-fill h-full gap-300 pt-600 px-600 text-primary">
        <div className="flex-row gap-400">
          <div className="w-1600 h-1600 rounded-100 overflow-clip border-input-border-width">
            {style.thumbnail_url ? (
              <CDNImage
                src={style.thumbnail_url}
                className="w-full h-full"
                imageClassName="h-full w-full object-cover object-center"
                srcDimension="small128"
              />
            ) : style.uuid === GENERIC_STYLE_UUID ? (
              <div className="w-full h-full flex-row-center bg-surface-emphasis-primary-rest">
                <PimentoStar size={32} className="fill-button-primary-rest" />
              </div>
            ) : (
              <div className="flex-col-center h-full w-full bg-surface-secondary-rest" />
            )}
          </div>
          <div className="flex-col flex-fill justify-center gap-150">
            <div className="heading-xl w-[90%] truncate">
              {style.name ? style.name : "Untitled"}
            </div>
            <div className="flex-row items-center w-fit gap-100">
              {(() => {
                switch (styleRetrainStatus) {
                  case "training":
                    return (
                      <Badge
                        size="sm"
                        iconName="LoaderCircle"
                        color="cautionSecondary"
                        label="Model is training"
                      />
                    );
                  case "nonRetrainable":
                    return (
                      <Badge
                        size="sm"
                        color="errorSecondary"
                        label="Not mixable"
                        iconName="TriangleAlert"
                      />
                    );
                  case "retrainable":
                    return (
                      <>
                        <Badge
                          size="sm"
                          color="cautionSecondary"
                          label="Needs retrain"
                          iconName="TriangleAlert"
                        />
                        {isUserAllowed && (
                          <Button
                            onClick={retrain}
                            loading={isLoading}
                            disabled={isRetrainDisabled}
                            size="sm"
                            variant="secondary"
                          >
                            Retrain model
                          </Button>
                        )}
                      </>
                    );
                  case "upToDate":
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
        <div className="flex-col flex-fill overflow-auto gap-400">
          {style.uuid === GENERIC_STYLE_UUID && (
            <div className="p-400 bg-input-surface-rest rounded-100 body-md-default">
              Generate any image you want in any style. Be more precise in your
              description than with other models, to get more precise results.
            </div>
          )}
          <div className="flex-col gap-200">
            <div className="items-center label-md-semibold">Attributes</div>
            <ModelDetails styleUuid={styleUuid} />
          </div>
          <div className="flex-col gap-200">
            <span className="items-center label-md-semibold">
              Initial images
            </span>
            <div className="grid grid-cols-4 gap-100">
              {style.uuid === GENERIC_STYLE_UUID
                ? GENERIC_FAKE_IMAGES.map((url) => (
                    <div key={url} className="h-full w-full aspect-square">
                      <Image
                        className="h-full w-full aspect-square"
                        imageClassName="h-full w-full object-cover object-center rounded-050"
                        src={url}
                      />
                    </div>
                  ))
                : style.training_images.map((image) => (
                    <div
                      key={image.uuid}
                      className="h-full w-full aspect-square"
                    >
                      <CDNImage
                        className="h-full w-full aspect-square"
                        imageClassName="h-full w-full object-cover object-center rounded-050"
                        src={image.url}
                        srcDimension="medium256"
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const GENERIC_FAKE_IMAGES = [
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_1.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_2.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_3.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_4.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_5.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_6.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_7.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_8.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_9.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_10.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_11.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_12.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_13.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_14.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_15.jpg",
] as const;
