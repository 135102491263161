import { type User as OAuthUser, useAuth0 } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LDProvider, useFlags } from "launchdarkly-react-client-sdk";
import { type ReactNode, useState } from "react";
import { isMobile } from "react-device-detect";
import { Navigate, Route } from "react-router";
import { BrowserRouter, Routes, useSearchParams } from "react-router-dom";
import { Animation } from "./components/Animation/Animation.tsx";
import { Button } from "./components/Button/Button.tsx";
import { TooltipProvider } from "./components/Tooltip/Tooltip.tsx";
import { useOnMount } from "./hooks/useOnMount.ts";
import { useAppMutation } from "./http/useAppMutation.ts";
import { useAppSubscription } from "./http/useAppSubscription.ts";
import { ADMIN, BOARDS, HOME, PAYMENT_CONFIRMATION, STYLES } from "./routes.ts";
import { AuthProvider } from "./singletons/AuthProvider.tsx";
import { ErrorBoundary } from "./singletons/ErrorBoundary.tsx";
import { NotificationsContainer } from "./singletons/NotificationsContainer/NotificationsContainer.tsx";
import { UpgradeDialog } from "./singletons/UpgradeDialog/UpgradeDialog.tsx";
import { initEventTracking, trackEvent } from "./utils/trackEvent.ts";
import { AdminView } from "./views/Admin/Admin.tsx";
import { BoardView } from "./views/Board/Board.tsx";
import { Maintenance } from "./views/Maintenance.tsx";
import { MobileView } from "./views/Mobile.tsx";
import { ModelCreationView } from "./views/StyleCreation/ModelCreationView.tsx";
/**
 * This should be the last import to allow tailwind utilities to override any previously imported css file
 */
import "./index.css";
import { pushEnhancedConversion } from "./utils/google_ads.ts";
import {
  getAndRemoveUtmFromLocalStorage,
  storeAndRemoveUtmFromSearchParams,
} from "./utils/utm.ts";
import { HomeView } from "./views/Home/HomeView.tsx";
import { PaymentConfirmationView } from "./views/PaymentConfirmation/PaymentConfirmationView.tsx";

export const App = () => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export const AppRouter = () => {
  const [, setSearchParams] = useSearchParams();

  useOnMount(() => {
    const searchParamsWithoutUtm = storeAndRemoveUtmFromSearchParams();
    setSearchParams(searchParamsWithoutUtm);
  });
  return (
    <AuthProvider>
      <TooltipProvider delayDuration={500}>
        <UpgradeDialog />
        <NotificationsContainer />
        <AuthSwitch />
      </TooltipProvider>
    </AuthProvider>
  );
};

const AuthSwitch = () => {
  const { user, isLoading, error, loginWithRedirect, logout } = useAuth0();

  if (error && user === undefined) {
    return (
      <div className=" w-full h-full flex-col items-center space-y-400 text-primary">
        {error.message === "Please verify your email before continuing." ? (
          <div className="flex-fill flex-col-center gap-800">
            <div className="flex-fill max-h-[300px] flex-col-center pt-600">
              <Animation
                loadData={() => import("./assets/boardInitLoading.json")}
                loop
                autoplay
                className="h-full"
              />
            </div>
            <div className="heading-2xl">Confirm your email</div>
            <div className="p-200 flex-col-center body-lg-default text-center">
              <span>Please check your email for a confirmation link.</span>
              <span>
                Have a look at your spams or contact us at&nbsp;
                <a href="mailto:contact@pimento.design" className="underline">
                  contact@pimento.design
                </a>
                &nbsp;if you didn’t received it!
              </span>
            </div>
          </div>
        ) : (
          <div className="flex-fill flex-col items-center body-lg-default justify-end space-y-400">
            <span className="mb-150 text-error-secondary">{error.message}</span>
            <span className="mb-150">Are you using the right account?</span>
          </div>
        )}
        <div className="flex-fill flex-col-center">
          <Button onClick={() => logout()}>Go back to login</Button>
        </div>
      </div>
    );
  }

  if (isLoading) return null;

  if (!user) {
    void loginWithRedirect({
      appState: { returnTo: window.location },
    });
    return null;
  }

  return (
    <UserSync>
      <AuthApp oauthUser={user} />
    </UserSync>
  );
};

// XXX: the goal of this component is to sync the user with the backend before rendering anything else
// if the user is unknown in the backend which happens the first time they log in the ws and boards route calls will fail
const UserSync = ({ children }: { children: ReactNode }) => {
  const [userSyncState, setUserSyncState] = useState<
    "LOADING" | "SUCCESS" | "ERROR"
  >("LOADING");
  const loginMutation = useAppMutation<{ uuid: string; email: string }>({
    path: "users/login",
    invalidate: [],
    onSuccess: ({ data }) => {
      setUserSyncState("SUCCESS");
      initEventTracking(data);
      void pushEnhancedConversion(data.email);
      // XXX: we moved the user:login event tracking from the backend to the frontend to be sure to have the browser info of the user
      trackEvent("user:login", getAndRemoveUtmFromLocalStorage());
    },
    onError: () => {
      setUserSyncState("ERROR");
    },
  }).mutation;

  useOnMount(() => {
    loginMutation.mutate({});
  });

  return userSyncState === "ERROR" ? (
    <div className="w-full h-full flex-col-center gap-300">
      <div>
        Failed login: it looks like we couldn't find you in our records. Please
        try again.
      </div>
      <Button onClick={() => loginMutation.mutate({})}>Retry</Button>
    </div>
  ) : (
    userSyncState === "SUCCESS" && children
  );
};

const AuthApp = ({ oauthUser }: { oauthUser: OAuthUser }) => {
  useAppSubscription();

  return (
    <LDProvider
      clientSideID="6464dbe8b4e764137e97340f"
      context={{
        kind: "user",
        key: oauthUser.sub,
        firstName: oauthUser.given_name,
      }}
    >
      <AuthRouter />
    </LDProvider>
  );
};

const AuthRouter = () => {
  const flags = useFlags();

  if (flags["maintenance"]) {
    return (
      <Routes>
        <Route path="*" element={<Maintenance />} />
      </Routes>
    );
  }
  if (isMobile) {
    return (
      <Routes>
        <Route path="*" element={<MobileView />} />
      </Routes>
    );
  }

  return (
    <div className="flex-fill bg-surface-primary-rest">
      <Routes>
        <Route path={`${HOME}/*`} element={<HomeView />} />
        <Route path={`${BOARDS}/:boardUuid/*`} element={<BoardView />} />
        <Route
          path={`${STYLES}/:styleUuid/*`}
          element={<ModelCreationView />}
        />
        <Route
          path={PAYMENT_CONFIRMATION}
          element={<PaymentConfirmationView />}
        />
        <Route path={ADMIN} element={<AdminView />} />
        <Route path="*" element={<Navigate replace to={HOME} />} />
      </Routes>
    </div>
  );
};
