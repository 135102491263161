import classNames from "classnames";
import { useMemo, useState } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Button } from "../../../components/Button/Button.tsx";
import { Icon, type IconName } from "../../../components/Icon/Icon.tsx";
import { TextField } from "../../../components/TextField/TextField.tsx";
import { useCreateStyle } from "../../Home/HomeIndex/hooks/useCreateStyle.ts";
import {
  CUSTOM_TAG,
  POPULAR_TAG,
} from "../../Home/HomeIndex/Style/constants.ts";
import type { Style } from "../../Home/HomeIndex/types.ts";
import type { StyleType } from "../../types.ts";
import { countNumberOfOccurencesPerStyleTags } from "../../utils/countNumberOfOccurencesPerStyleTags.ts";
import type { StylePickerNavigationFilter } from "./types.ts";

export const StylePrimaryNavigation = ({
  styles,
  stylePickerNavigationFilters,
  setStylePickerNavigationFilters,
  styleTypeFilter,
}: {
  styles: Style[];
  stylePickerNavigationFilters: StylePickerNavigationFilter;
  setStylePickerNavigationFilters: (
    navigationFilters: StylePickerNavigationFilter,
  ) => void;
  styleTypeFilter?: StyleType;
}) => {
  const { createStyle, isCreateStyleLoading } = useCreateStyle();

  const [activeStyleType, setActiveStyleType] = useState<StyleType | null>(
    null,
  );

  return (
    <div className="flex-col h-full w-[240px] pt-300 bg-surface-secondary-rest text-primary">
      <div className="flex-col gap-150 pb-150 px-300 border-b">
        <Button
          variant="secondary"
          size="md"
          loading={isCreateStyleLoading}
          onClick={createStyle}
          iconName="Plus"
        >
          Create new model
        </Button>
        <TextField
          placeholder="Search..."
          size="md"
          value={stylePickerNavigationFilters.searchText}
          onChange={(value) => {
            setStylePickerNavigationFilters({
              ...stylePickerNavigationFilters,
              searchText: value,
            });
          }}
          rightIconName="Search"
          autoFocus
          onClick={() => {
            setStylePickerNavigationFilters({
              ...stylePickerNavigationFilters,
              isTextSearchEnabled: true,
              selectedTag: null,
              selectedStyleType: null,
            });
          }}
        />
      </div>
      <div className="flex-col px-300">
        <div className="flex-col py-150">
          <StylePickerPrimaryNavigationButton
            onClick={() => {
              setStylePickerNavigationFilters({
                ...stylePickerNavigationFilters,
                selectedTag: POPULAR_TAG,
                selectedStyleType: null,
                isTextSearchEnabled: false,
              });
            }}
            isActive={
              stylePickerNavigationFilters.selectedTag === POPULAR_TAG &&
              stylePickerNavigationFilters.selectedStyleType === null
            }
            iconName="Star"
            itemName="Popular"
            suffix={styles.filter((it) => it.tags.includes(POPULAR_TAG)).length}
          />
          <StylePickerPrimaryNavigationButton
            onClick={() => {
              setStylePickerNavigationFilters({
                ...stylePickerNavigationFilters,
                selectedTag: CUSTOM_TAG,
                selectedStyleType: null,
                isTextSearchEnabled: false,
              });
            }}
            isActive={
              stylePickerNavigationFilters.selectedTag === CUSTOM_TAG &&
              stylePickerNavigationFilters.selectedStyleType === null
            }
            iconName="UserRound"
            itemName={`My ${
              styleTypeFilter === "style"
                ? "styles"
                : styleTypeFilter === "object"
                ? "objects"
                : styleTypeFilter === "character"
                ? "characters"
                : "models"
            }`}
            suffix={
              styles.filter(
                (it) =>
                  (styleTypeFilter ? it.type === styleTypeFilter : true) &&
                  !it.is_curated,
              ).length
            }
          />
        </div>
        <div className="flex-col py-150">
          {(!styleTypeFilter || styleTypeFilter === "style") && (
            <StylePickerPrimaryNavigationTitle
              onClick={() => {
                setActiveStyleType((type) =>
                  type === "style" ? null : "style",
                );
                setStylePickerNavigationFilters({
                  ...stylePickerNavigationFilters,
                  selectedTag: "",
                  selectedStyleType: "style",
                  isTextSearchEnabled: false,
                });
              }}
              styles={styles}
              styleType="style"
              isOpen={activeStyleType === "style"}
              stylePickerNavigationFilters={stylePickerNavigationFilters}
              setStylePickerNavigationFilters={setStylePickerNavigationFilters}
              isUserStyleButtonDisplayed={!styleTypeFilter}
            />
          )}
          {(!styleTypeFilter || styleTypeFilter === "character") && (
            <StylePickerPrimaryNavigationTitle
              onClick={() => {
                setActiveStyleType((type) =>
                  type === "character" ? null : "character",
                );
                setStylePickerNavigationFilters({
                  ...stylePickerNavigationFilters,
                  selectedTag: "",
                  selectedStyleType: "character",
                  isTextSearchEnabled: false,
                });
              }}
              styles={styles}
              styleType="character"
              isOpen={activeStyleType === "character"}
              stylePickerNavigationFilters={stylePickerNavigationFilters}
              setStylePickerNavigationFilters={setStylePickerNavigationFilters}
              isUserStyleButtonDisplayed={!styleTypeFilter}
            />
          )}
          {(!styleTypeFilter || styleTypeFilter === "object") && (
            <StylePickerPrimaryNavigationTitle
              onClick={() => {
                setActiveStyleType((type) =>
                  type === "object" ? null : "object",
                );
                setStylePickerNavigationFilters({
                  ...stylePickerNavigationFilters,
                  selectedTag: "",
                  selectedStyleType: "object",
                  isTextSearchEnabled: false,
                });
              }}
              styles={styles}
              styleType="object"
              isOpen={activeStyleType === "object"}
              stylePickerNavigationFilters={stylePickerNavigationFilters}
              setStylePickerNavigationFilters={setStylePickerNavigationFilters}
              isUserStyleButtonDisplayed={!styleTypeFilter}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const StylePickerPrimaryNavigationTitle = ({
  onClick,
  isOpen,
  styleType,
  styles,
  stylePickerNavigationFilters,
  setStylePickerNavigationFilters,
  isUserStyleButtonDisplayed,
}: {
  onClick: () => void;
  isOpen: boolean;
  styleType: StyleType;
  styles: Style[];
  stylePickerNavigationFilters: StylePickerNavigationFilter;
  setStylePickerNavigationFilters: (
    navigationFilters: StylePickerNavigationFilter,
  ) => void;
  isUserStyleButtonDisplayed: boolean;
}) => {
  const filteredStyles = useMemo(
    () => styles.filter((it) => it.type === styleType),
    [styles, styleType],
  );

  const filteredStylesCountByTags = useMemo(
    () => countNumberOfOccurencesPerStyleTags(filteredStyles),
    [filteredStyles],
  );

  const categoryName =
    styleType === "style"
      ? "styles"
      : styleType === "object"
      ? "objects"
      : "characters";

  return (
    <div className="flex-col">
      <BaseButton onClick={onClick}>
        <div className="flex-row items-center h-800 gap-150 pl-150 pr-200 rounded-150 text-secondary label-md-default hover:bg-surface-secondary-hover">
          <Icon
            name={
              styleType === "style"
                ? "Droplets"
                : styleType === "object"
                ? "Shirt"
                : "PersonStanding"
            }
            size="md"
            className="stroke-secondary-rest"
          />
          <div className="flex-fill first-letter:uppercase">{categoryName}</div>
          <div className="h-400 w-400 text-end">
            {isOpen ? "" : filteredStyles.length}
          </div>
          <Icon name="ChevronDown" size="xs" rotate={isOpen ? 180 : 0} />
        </div>
      </BaseButton>
      {isOpen && (
        <div className="flex-col">
          {isUserStyleButtonDisplayed && (
            <StylePickerPrimaryNavigationSecondaryButton
              onClick={() => {
                setStylePickerNavigationFilters({
                  ...stylePickerNavigationFilters,
                  selectedTag: CUSTOM_TAG,
                  selectedStyleType: styleType,
                  isTextSearchEnabled: false,
                });
              }}
              isActive={
                stylePickerNavigationFilters.selectedTag === CUSTOM_TAG &&
                styleType === stylePickerNavigationFilters.selectedStyleType
              }
              itemName={`My ${categoryName}`}
              suffix={filteredStyles.filter((it) => !it.is_curated).length}
            />
          )}
          <StylePickerPrimaryNavigationSecondaryButton
            onClick={() => {
              setStylePickerNavigationFilters({
                ...stylePickerNavigationFilters,
                selectedTag: "",
                selectedStyleType: styleType,
                isTextSearchEnabled: false,
              });
            }}
            isActive={
              stylePickerNavigationFilters.selectedTag === "" &&
              styleType === stylePickerNavigationFilters.selectedStyleType
            }
            itemName={`All ${categoryName}`}
            suffix={filteredStyles.length}
          />
          {Object.keys(filteredStylesCountByTags)
            .filter((tagName) => tagName !== POPULAR_TAG)
            .sort()
            .map((tagName) => (
              <StylePickerPrimaryNavigationSecondaryButton
                key={tagName}
                onClick={() => {
                  setStylePickerNavigationFilters({
                    ...stylePickerNavigationFilters,
                    selectedTag: tagName,
                    selectedStyleType: styleType,
                    isTextSearchEnabled: false,
                  });
                }}
                isActive={
                  stylePickerNavigationFilters.selectedTag === tagName &&
                  styleType === stylePickerNavigationFilters.selectedStyleType
                }
                itemName={tagName}
                suffix={filteredStylesCountByTags[tagName] ?? 0}
              />
            ))}
        </div>
      )}
    </div>
  );
};

const StylePickerPrimaryNavigationButton = ({
  onClick,
  isActive,
  iconName,
  itemName,
  suffix,
}: {
  onClick?: () => void;
  isActive: boolean;
  iconName: IconName;
  itemName: string;
  suffix: number;
}) => (
  <BaseButton onClick={onClick}>
    <div
      className={classNames(
        isActive
          ? "text-primary label-md-semibold bg-surface-secondary-active"
          : "text-secondary label-md-default hover:bg-surface-secondary-hover",
        "flex-row items-center h-800 gap-150 pl-150 pr-200 rounded-150",
      )}
    >
      <Icon
        name={iconName}
        size="md"
        className={isActive ? "stroke-primary-rest" : "stroke-secondary-rest"}
      />
      <div className="flex-fill">{itemName}</div>
      <div className="h-400 w-400 text-end">{suffix}</div>
    </div>
  </BaseButton>
);

const StylePickerPrimaryNavigationSecondaryButton = ({
  onClick,
  isActive,
  itemName,
  suffix,
}: {
  onClick: () => void;
  isActive: boolean;
  itemName: string;
  suffix: number;
}) => (
  <BaseButton onClick={onClick}>
    <div
      className={classNames(
        isActive
          ? "text-primary label-md-semibold bg-surface-secondary-active"
          : "text-secondary label-md-default hover:bg-surface-secondary-hover",
        "flex-row items-center h-800 gap-150 pl-800 pr-200 rounded-150",
      )}
    >
      <div className="flex-fill">{itemName}</div>
      <div className="h-400 w-400 text-center">{suffix}</div>
    </div>
  </BaseButton>
);
