import type { Style } from "../../../../types.ts";
import { PresetButton } from "../../../components/PresetButton.tsx";
import type { MLModelArchitecture } from "../../../types.ts";

export const MlModelArchitectureSection = ({
  style,
  mlModelArchitecture,
  onClickMlModelArchitecture,
}: {
  style: Style | undefined;
  mlModelArchitecture?: MLModelArchitecture;
  onClickMlModelArchitecture: (
    mlModelArchitecture: MLModelArchitecture | undefined,
  ) => void;
}) =>
  style && (
    <div className="flex-col gap-200 text-primary body-md-default">
      Model Architecture
      <div className="flex-row-center gap-100">
        {style.ml_model_architectures.map((architecture) => (
          <PresetButton
            key={architecture}
            isSelected={mlModelArchitecture === architecture}
            onClick={onClickMlModelArchitecture.bind(null, architecture)}
          >
            {architecture}
          </PresetButton>
        ))}
        <PresetButton
          key="default"
          isSelected={mlModelArchitecture === undefined}
          onClick={onClickMlModelArchitecture.bind(null, undefined)}
        >
          Default
        </PresetButton>
      </div>
    </div>
  );
