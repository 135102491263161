import { useEffect, useState } from "react";
import { TagInput } from "../../../components/TagInput/TagInput.tsx";
import { TextField } from "../../../components/TextField/TextField.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import type {
  Graphic3DModelStyle,
  IconModelStyle,
  IllustrationColorDetails,
  IllustrationModelStyle,
  ModelStyle,
  ObjectDetails,
  ObjectStyle,
  PhotographyColorDetails,
  PhotographyModelStyle,
  PhotographyStyleDetails,
  Style,
} from "../../types.ts";
import { StyleTypeDisplay } from "./constants.ts";

export const ModelDetails = ({ styleUuid }: { styleUuid: string }) => {
  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${styleUuid}`],
  }).mutation;

  const { data: style } = useAppQuery<Style>({
    queryKey: `styles/${styleUuid}`,
  });
  const { user } = useUser();

  // XXX: User can update style only if the style is its own or if the user has the permission to update curated styles
  const isStyleUpdateDisabled =
    style?.is_curated &&
    !user?.permissions.includes("styles:update-curated-styles");

  const [additionalElements, setAdditionalElements] = useState<string>("");

  useEffect(() => {
    setAdditionalElements(style?.additional_elements ?? "");
  }, [style]);

  return (
    <div className="w-full grid grid-cols-[3fr_7fr] gap-200">
      {(() => {
        switch (style?.type) {
          case "style":
            return (
              <StyleModelDetails
                style={style}
                isStyleUpdateDisabled={isStyleUpdateDisabled ?? false}
              />
            );
          case "character":
            return null;
          case "object":
            return (
              <ObjectStyleDetails
                style={style}
                isStyleUpdateDisabled={isStyleUpdateDisabled ?? false}
              />
            );
          case null:
            return null;
          case undefined:
            return null;
        }
      })()}
      <div className="py-100 label-md-semibold text-disabled">
        Additional elements
      </div>
      <TextField
        value={additionalElements}
        onChange={setAdditionalElements}
        size="sm"
        placeholder="Additional elements"
        onBlur={() =>
          updateStyle({
            uuid: styleUuid,
            additional_elements: additionalElements,
          })
        }
        disabled={isStyleUpdateDisabled}
      />
    </div>
  );
};

export const StyleModelDetails = ({
  style,
  isStyleUpdateDisabled,
}: {
  style: ModelStyle;
  isStyleUpdateDisabled: boolean;
}) => (
  <>
    {style.images_kind && (
      <>
        <div className="py-100 label-md-semibold text-disabled">Type</div>
        <TextField
          value={StyleTypeDisplay[style.images_kind]}
          onChange={() => null}
          size="sm"
          placeholder="Style type"
          disabled
        />
      </>
    )}
    {(() => {
      switch (style.images_kind) {
        case "photography":
          return (
            <PhotographyModelStyleDetails
              style={style}
              isStyleUpdateDisabled={isStyleUpdateDisabled}
            />
          );
        case "illustration_2d":
          return (
            <IllustrationModelStyleDetails
              style={style}
              isStyleUpdateDisabled={isStyleUpdateDisabled}
            />
          );
        case "graphic_3d":
          return (
            <IllustrationModelStyleDetails
              style={style}
              isStyleUpdateDisabled={isStyleUpdateDisabled}
            />
          );
        case "icon":
          return (
            <IllustrationModelStyleDetails
              style={style}
              isStyleUpdateDisabled={isStyleUpdateDisabled}
            />
          );
        case "other":
          return null;
        case null:
          return null;
      }
    })()}
  </>
);

export const PhotographyModelStyleDetails = ({
  style,
  isStyleUpdateDisabled,
}: {
  style: PhotographyModelStyle;
  isStyleUpdateDisabled: boolean;
}) => {
  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${style.uuid}`],
  }).mutation;

  const [colorDetails, setColorDetails] =
    useState<PhotographyColorDetails | null>(null);
  const [styleDetails, setStyleDetails] =
    useState<PhotographyStyleDetails | null>(null);

  useEffect(() => {
    setColorDetails(style.color_details);
    setStyleDetails(style.style_details);
  }, [style]);

  return (
    <>
      <div className="py-100 label-md-semibold text-disabled">Colors</div>
      <TagInput
        tagValues={colorDetails?.palette ?? []}
        onChange={(tags) => {
          setColorDetails({ palette: tags });
          updateStyle({
            uuid: style.uuid,
            color_palette: tags,
          });
        }}
        size="sm"
        leftIconName="Palette"
        isDisabled={isStyleUpdateDisabled}
      />
      <div className="py-100 label-md-semibold text-disabled">Exposure</div>
      <TagInput
        tagValues={styleDetails?.exposure ?? []}
        onChange={(tags) => {
          setStyleDetails({
            exposure: tags,
            composition: styleDetails?.composition ?? [],
            camera_type: styleDetails?.camera_type ?? [],
            focus: styleDetails?.focus ?? [],
            image_quality: styleDetails?.image_quality ?? [],
          });
          updateStyle({
            uuid: style.uuid,
            exposure: tags,
          });
        }}
        size="sm"
        leftIconName="LampCeiling"
        isDisabled={isStyleUpdateDisabled}
      />
      <div className="py-100 label-md-semibold text-disabled">Composition</div>
      <TagInput
        tagValues={styleDetails?.composition ?? []}
        onChange={(tags) => {
          setStyleDetails({
            exposure: styleDetails?.exposure ?? [],
            composition: tags,
            camera_type: styleDetails?.camera_type ?? [],
            focus: styleDetails?.focus ?? [],
            image_quality: styleDetails?.image_quality ?? [],
          });
          updateStyle({
            uuid: style.uuid,
            composition: tags,
          });
        }}
        size="sm"
        leftIconName="Frame"
        isDisabled={isStyleUpdateDisabled}
      />
      <div className="py-100 label-md-semibold text-disabled">Focus</div>
      <TagInput
        tagValues={styleDetails?.focus ?? []}
        onChange={(tags) => {
          setStyleDetails({
            exposure: styleDetails?.exposure ?? [],
            composition: styleDetails?.composition ?? [],
            camera_type: styleDetails?.camera_type ?? [],
            focus: tags,
            image_quality: styleDetails?.image_quality ?? [],
          });
          updateStyle({
            uuid: style.uuid,
            focus: tags,
          });
        }}
        size="sm"
        leftIconName="Focus"
        isDisabled={isStyleUpdateDisabled}
      />
      <div className="py-100 label-md-semibold text-disabled">Quality</div>
      <TagInput
        tagValues={styleDetails?.image_quality ?? []}
        onChange={(tags) => {
          setStyleDetails({
            exposure: styleDetails?.exposure ?? [],
            composition: styleDetails?.composition ?? [],
            camera_type: styleDetails?.camera_type ?? [],
            focus: styleDetails?.focus ?? [],
            image_quality: tags,
          });
          updateStyle({
            uuid: style.uuid,
            image_quality: tags,
          });
        }}
        size="sm"
        leftIconName="ImageOff"
        isDisabled={isStyleUpdateDisabled}
      />
      <div className="py-100 label-md-semibold text-disabled">Camera type</div>
      <TagInput
        tagValues={styleDetails?.camera_type ?? []}
        onChange={(tags) => {
          setStyleDetails({
            exposure: styleDetails?.exposure ?? [],
            composition: styleDetails?.composition ?? [],
            camera_type: tags,
            focus: styleDetails?.focus ?? [],
            image_quality: styleDetails?.image_quality ?? [],
          });
          updateStyle({
            uuid: style.uuid,
            camera_type: tags,
          });
        }}
        size="sm"
        leftIconName="Video"
        isDisabled={isStyleUpdateDisabled}
      />
    </>
  );
};

export const IllustrationModelStyleDetails = ({
  style,
  isStyleUpdateDisabled,
}: {
  style: IllustrationModelStyle | IconModelStyle | Graphic3DModelStyle;
  isStyleUpdateDisabled: boolean;
}) => {
  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${style.uuid}`],
  }).mutation;

  const [colorDetails, setColorDetails] =
    useState<IllustrationColorDetails | null>(null);
  const [composition, setComposition] = useState<string[]>([]);
  const [levelOfDetail, setLevelOfDetail] = useState<string[]>([]);
  const [shape, setShape] = useState<string[]>([]);
  const [stroke, setStroke] = useState<string[]>([]);

  useEffect(() => {
    setColorDetails(style.color_details);
    setComposition(style.style_details?.composition ?? []);
    setLevelOfDetail(style.style_details?.level_of_detail ?? []);
    setShape(style.style_details?.shape ?? []);
    if (
      style.images_kind === "illustration_2d" ||
      style.images_kind === "icon"
    ) {
      setStroke(style.style_details?.stroke ?? []);
    }
  }, [style]);

  return (
    <>
      <div className="py-100 label-md-semibold text-disabled">Colors</div>
      <TagInput
        tagValues={colorDetails?.palette ?? []}
        onChange={(tags) => {
          setColorDetails({
            palette: tags,
            texture: colorDetails?.texture ?? [],
          });
          updateStyle({
            uuid: style.uuid,
            color_palette: tags,
          });
        }}
        size="sm"
        leftIconName="Palette"
        isDisabled={isStyleUpdateDisabled}
      />
      <div className="py-100 label-md-semibold text-disabled">Texture</div>
      <TagInput
        tagValues={colorDetails?.texture ?? []}
        onChange={(tags) => {
          setColorDetails({
            palette: colorDetails?.palette ?? [],
            texture: tags,
          });
          updateStyle({
            uuid: style.uuid,
            color_texture: tags,
          });
        }}
        size="sm"
        leftIconName="LampDesk"
        isDisabled={isStyleUpdateDisabled}
      />
      <div className="py-100 label-md-semibold text-disabled">Composition</div>
      <TagInput
        tagValues={composition}
        onChange={(tags) => {
          setComposition(tags);
          updateStyle({
            uuid: style.uuid,
            composition: tags,
          });
        }}
        size="sm"
        leftIconName="Frame"
        isDisabled={isStyleUpdateDisabled}
      />
      <div className="py-100 label-md-semibold text-disabled">
        Level of detail
      </div>
      <TagInput
        tagValues={levelOfDetail}
        onChange={(tags) => {
          setLevelOfDetail(tags);
          updateStyle({
            uuid: style.uuid,
            level_of_detail: tags,
          });
        }}
        size="sm"
        leftIconName="Gauge"
        isDisabled={isStyleUpdateDisabled}
      />
      <div className="py-100 label-md-semibold text-disabled">Shape</div>
      <TagInput
        tagValues={shape}
        onChange={(tags) => {
          setShape(tags);
          updateStyle({
            uuid: style.uuid,
            shape: tags,
          });
        }}
        size="sm"
        leftIconName="Cone"
        isDisabled={isStyleUpdateDisabled}
      />
      {(style.images_kind === "illustration_2d" ||
        style.images_kind === "icon") && (
        <>
          <div className="py-100 label-md-semibold text-disabled">Stroke</div>
          <TagInput
            tagValues={stroke}
            onChange={(tags) => {
              setStroke(stroke);
              updateStyle({
                uuid: style.uuid,
                stroke: tags,
              });
            }}
            size="sm"
            leftIconName="PencilLine"
            isDisabled={isStyleUpdateDisabled}
          />
        </>
      )}
    </>
  );
};

export const ObjectStyleDetails = ({
  style,
  isStyleUpdateDisabled,
}: {
  style: ObjectStyle;
  isStyleUpdateDisabled: boolean;
}) => {
  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${style.uuid}`],
  }).mutation;

  const [objectDetails, setObjectDetails] = useState<ObjectDetails | null>(
    null,
  );

  useEffect(() => {
    setObjectDetails(style.object_details);
  }, [style]);

  return (
    <>
      <div className="py-100 label-md-semibold text-disabled">Materials</div>
      <TagInput
        tagValues={objectDetails?.materials ?? []}
        onChange={(tags) => {
          setObjectDetails({
            materials: tags,
            labels: objectDetails?.labels ?? [],
          });
          updateStyle({
            uuid: style.uuid,
            materials: tags,
          });
        }}
        size="sm"
        leftIconName="BrickWall"
        isDisabled={isStyleUpdateDisabled}
      />
      <div className="py-100 label-md-semibold text-disabled">Labels</div>
      <TagInput
        tagValues={objectDetails?.labels ?? []}
        onChange={(tags) => {
          setObjectDetails({
            materials: objectDetails?.materials ?? [],
            labels: tags,
          });
          updateStyle({
            uuid: style.uuid,
            labels: tags,
          });
        }}
        size="sm"
        leftIconName="Type"
        isDisabled={isStyleUpdateDisabled}
      />
    </>
  );
};
