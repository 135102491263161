import { useAppQuery } from "../../../../http/useAppQuery.ts";
import type { Style } from "../types.ts";

export const useStyles = () => {
  const stylesQuery = useAppQuery<Style[]>({ queryKey: "styles" });

  const userStyles = stylesQuery.data?.filter((board) => !board.is_curated);
  const libraryStyles = stylesQuery.data?.filter((board) => board.is_curated);

  return {
    userStyles,
    libraryStyles,
  };
};
