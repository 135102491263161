import { useEffect, useState } from "react";
import { BaseButton } from "../../../../../../components/Button/BaseButton.tsx";
import { Slider } from "../../../../../../components/Slider/Slider.tsx";
import { useSelectedAsset } from "../../../../hooks/useSelectedAsset.ts";
import { clearObjects } from "../../components/BaseEditor/utils.ts";
import { generativeFillCanvasStore } from "./stores/generativeFillCanvasStore.ts";

export const GenerativeFillToolbar = () => {
  const { fabricCanvas, numObjectsCreated, fillMode, brushSize } =
    generativeFillCanvasStore.useState();
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  //  XXX: Instead of using directly the numObjectsCreated, we rather check directly if the canvas is empty to avoid
  //  any potential side effects when mixing history modifications and object creations.
  useEffect(() => {
    setIsEmpty(fabricCanvas?.isEmpty() ?? false);
  }, [numObjectsCreated, fabricCanvas]);

  const { selectedAsset } = useSelectedAsset();

  return !(fabricCanvas && selectedAsset) ? null : (
    <div className="flex-row-center body-md-default text-primary gap-200">
      {fillMode === "brush" && (
        <div className="flex-row-center gap-200">
          <div>Brush Size</div>
          <Slider
            min={24}
            max={124}
            displayNumber={false}
            value={brushSize}
            onChange={(value) => {
              fabricCanvas.freeDrawingBrush.width = value;
              generativeFillCanvasStore.setBrushSize(value);
            }}
            className="w-[120px]"
          />
          <div className="border border-l h-600" />
        </div>
      )}
      <BaseButton
        className="h-full flex-row-center text-primary body-md-default hover:text-emphasis-secondary disabled:text-secondary"
        onClick={() => {
          clearObjects(fabricCanvas);
          // @ts-expect-error history.js is not typed
          fabricCanvas.clearHistory();
        }}
        disabled={isEmpty}
      >
        Clear
      </BaseButton>
    </div>
  );
};
