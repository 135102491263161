const SESSION_STORAGE_KEY = "returnPath";

export const saveReturnPath = () => {
  sessionStorage.setItem(
    SESSION_STORAGE_KEY,
    window.location.pathname + window.location.search,
  );
};

export const getReturnPath = () => sessionStorage.getItem(SESSION_STORAGE_KEY);
