import { createStore } from "../../utils/createStore.ts";
import { saveReturnPath } from "../../utils/returnPath.ts";

export const upgradeDialogStore = createStore(
  { isDialogOpen: false as boolean, isClosable: false as boolean },
  ({ setter }) => ({
    openDialog: ({ isClosable = false }: { isClosable?: boolean } = {}) => {
      setter("isDialogOpen")(true);
      setter("isClosable")(isClosable);
      saveReturnPath();
    },
    closeDialog: () => {
      setter("isDialogOpen")(false);
    },
  }),
);
