import { usePersistedState } from "../../../../../../../hooks/usePersistedState.ts";
import { useAppQuery } from "../../../../../../../http/useAppQuery.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import type {
  MLModelArchitecture,
  QualityPreset,
} from "../../../../../types.ts";
import type {
  GenerativeFillCreativityStrengthPreset,
  GenerativeFillCreativityStrengthPresets,
} from "../types.ts";

export type GenerativeFillSettings = {
  num_generations: number;
  prompt_strength: number;
  negative_prompt: string;
  padding_mask_crop: number;
  auto_patch: boolean;
  creativity_strength_preset: GenerativeFillCreativityStrengthPreset;
  quality_preset: QualityPreset;
  ml_model_architecture: MLModelArchitecture | undefined;
  guidance_scale: number;
};

export const GENERATIVE_FILL_DEFAULT_SETTINGS = {
  num_generations: 4,
  prompt_strength: 0.75,
  negative_prompt: "",
  padding_mask_crop: 64,
  auto_patch: true,
  creativity_strength_preset: "medium",
  quality_preset: "fast",
  guidance_scale: 0.5,
  ml_model_architecture: undefined,
} as const;

export const useGenerativeFillSettings = () => {
  const { board } = useBoard();
  const [generativeFillSettings, setGenerativeFillSettings] =
    usePersistedState<GenerativeFillSettings>({
      // XXX: we version the persisted state to reset defaults for existing boards
      key: `generative-fill-settings-v16-${board.uuid}`,
      defaultValue: GENERATIVE_FILL_DEFAULT_SETTINGS,
    });

  const { data: creativityStrengthPreset } =
    useAppQuery<GenerativeFillCreativityStrengthPresets>({
      queryKey: "presets/generative-fill",
    });

  return {
    generativeFillSettings,
    setGenerativeFillSettings: (
      newSettings: Partial<GenerativeFillSettings>,
    ) => {
      let creativityStrengthPresetSettings: Partial<GenerativeFillSettings> =
        {};
      if (newSettings.creativity_strength_preset && creativityStrengthPreset) {
        // XXX: remove presets equal to null for custom presets
        creativityStrengthPresetSettings = Object.fromEntries(
          Object.entries(
            creativityStrengthPreset[newSettings.creativity_strength_preset],
          ).filter(([_, v]) => v !== null),
        );
      }
      setGenerativeFillSettings((settings) => ({
        ...settings,
        ...newSettings,
        ...creativityStrengthPresetSettings,
      }));
    },
  } as {
    generativeFillSettings: GenerativeFillSettings;
    setGenerativeFillSettings: (
      newSettings: Partial<GenerativeFillSettings>,
    ) => void;
  };
};
