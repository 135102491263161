const UTM_SEARCH_KEYS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
] as const;

const LOCAL_STORAGE_UTM_KEY = "utmParams";

const storeUtmParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const utmParams = UTM_SEARCH_KEYS.reduce<{
    [Key: string]: string | null;
  }>((accumulator, searchKey) => {
    accumulator[searchKey] = searchParams.get(searchKey);
    return accumulator;
  }, {});

  if (Object.values(utmParams).some((param) => param)) {
    localStorage.setItem(LOCAL_STORAGE_UTM_KEY, JSON.stringify(utmParams));
  }
};

const removeUtmFromSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  for (const utmKey of UTM_SEARCH_KEYS) {
    searchParams.delete(utmKey);
  }
  return searchParams;
};

export const storeAndRemoveUtmFromSearchParams = () => {
  storeUtmParams();
  return removeUtmFromSearchParams();
};

export const getAndRemoveUtmFromLocalStorage = () => {
  const storedUtmParams = localStorage.getItem(LOCAL_STORAGE_UTM_KEY);

  if (storedUtmParams) {
    const params = JSON.parse(storedUtmParams);
    localStorage.removeItem("utmParams");
    return params;
  }
  return undefined;
};
