import { useAppQuery } from "../../../../http/useAppQuery.ts";
import type { Board } from "../types.ts"; // FIXME: these values should come from the backend directly.

export const useBoards = () => {
  const boardsQuery = useAppQuery<Board[]>({ queryKey: "boards" });

  return {
    userBoards: boardsQuery.data,
  };
};
