import { useEffect, useMemo, useState } from "react";
import { Button } from "../../../components/Button/Button.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import {
  CUSTOM_TAG,
  POPULAR_TAG,
} from "../../Home/HomeIndex/Style/constants.ts";
import type { Style } from "../../Home/HomeIndex/types.ts";
import type { StyleType } from "../../types.ts";
import { StyleDescription } from "./StyleDescription.tsx";
import { StylePrimaryNavigation } from "./StylePrimaryNavigation.tsx";
import { StyleSecondaryNavigation } from "./StyleSecondaryNavigation.tsx";
import type { StylePickerNavigationFilter } from "./types.ts";

export const StylePicker = ({
  dialogOpen,
  onDialogChange,
  onStyleSelect,
  selectionButtonName,
  styleTypeFilter,
  disableNonMixableStyles = false,
}: {
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onStyleSelect?: (styleUuid: string) => void;
  selectionButtonName?: string;
  styleTypeFilter?: StyleType;
  disableNonMixableStyles?: boolean;
}) => (
  <Dialog
    className="py-0"
    title="Select model"
    isOpen={dialogOpen}
    onOpenChange={(dialogChange) => {
      onDialogChange(dialogChange);
    }}
    content={
      dialogOpen && (
        <StylePickerContent
          onStyleSubmit={onStyleSelect}
          selectionButtonName={selectionButtonName}
          styleTypeFilter={styleTypeFilter}
          disableNonMixableStyles={disableNonMixableStyles}
        />
      )
    }
  />
);

const StylePickerContent = ({
  onStyleSubmit,
  selectionButtonName = "Use model in new project",
  styleTypeFilter,
  disableNonMixableStyles,
}: {
  onStyleSubmit?: (styleUuid: string) => void;
  selectionButtonName?: string;
  styleTypeFilter?: StyleType;
  disableNonMixableStyles: boolean;
}) => {
  const [selectedStyleUuid, setSelectedStyleUuid] = useState<
    string | undefined
  >(undefined);
  const [stylePickerNavigationFilters, setStylePickerNavigationFilters] =
    useState<StylePickerNavigationFilter>({
      selectedTag: POPULAR_TAG,
      selectedStyleType: null,
      searchText: "",
      isTextSearchEnabled: false,
    });

  // FIXME: We must improve the loading state of this query to avoid empty state
  const { data: styles } = useAppQuery<Style[]>({ queryKey: "styles" });

  const nonDraftStyles = useMemo(
    () =>
      styles?.filter(
        (style: Style) =>
          ["training", "ready"].includes(style.status) &&
          (styleTypeFilter ? style.type === styleTypeFilter : true),
      ),
    [styles, styleTypeFilter],
  );

  const filteredStyles = useMemo(
    () =>
      stylePickerNavigationFilters.isTextSearchEnabled
        ? nonDraftStyles?.filter(
            (style: Style) =>
              style.name
                ?.toLowerCase()
                .includes(
                  stylePickerNavigationFilters.searchText.toLowerCase(),
                ),
          )
        : nonDraftStyles?.filter(
            (style: Style) =>
              (stylePickerNavigationFilters.selectedStyleType
                ? style.type === stylePickerNavigationFilters.selectedStyleType
                : true) &&
              (stylePickerNavigationFilters.selectedTag === CUSTOM_TAG
                ? !style.is_curated
                : stylePickerNavigationFilters.selectedTag
                ? style.tags.includes(stylePickerNavigationFilters.selectedTag)
                : true),
          ),
    [nonDraftStyles, stylePickerNavigationFilters],
  );

  //  XXX: If the selected style do not match the filtered styles, we reset the selected style
  useEffect(() => {
    if (filteredStyles?.length && !selectedStyleUuid) {
      setSelectedStyleUuid(filteredStyles[0].uuid);
    } else if (!filteredStyles?.find((it) => it.uuid === selectedStyleUuid)) {
      setSelectedStyleUuid(filteredStyles?.[0]?.uuid);
    }
  }, [filteredStyles, selectedStyleUuid]);

  const selectedStyle = useMemo(
    () => styles?.find((it) => it.uuid === selectedStyleUuid),
    [styles, selectedStyleUuid],
  );

  return (
    <div className="h-[680px] border-t flex-fill flex-col text-primary">
      {styles && (
        <div className="flex-fill flex-row">
          <StylePrimaryNavigation
            styles={nonDraftStyles ?? []}
            styleTypeFilter={styleTypeFilter}
            stylePickerNavigationFilters={stylePickerNavigationFilters}
            setStylePickerNavigationFilters={setStylePickerNavigationFilters}
          />
          <StyleSecondaryNavigation
            styles={filteredStyles ?? []}
            onStyleUuidSelect={setSelectedStyleUuid}
            selectedStyleUuid={selectedStyleUuid}
          />
          <div className="flex-col flex-fill w-[480px] justify-end">
            {selectedStyleUuid && (
              <StyleDescription styleUuid={selectedStyleUuid} />
            )}
            <div className="flex-row w-full py-300 px-400 gap-200 items-center justify-end border-t">
              <Button
                onClick={() => {
                  if (selectedStyleUuid) onStyleSubmit?.(selectedStyleUuid);
                }}
                disabled={
                  selectedStyle?.status !== "ready" || disableNonMixableStyles
                    ? !selectedStyle?.is_mixable
                    : false
                }
                size="md"
                variant="primary"
              >
                {selectionButtonName}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
