import classNames from "classnames";
import { useState } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { BaseClickableIcon } from "../../../components/Icon/BaseClickableIcon.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { CDNImage } from "../../../components/Image/CDNImage.tsx";
import { Popover } from "../../../components/Popover/Popover.tsx";
import { Spinner } from "../../../components/Spinner/Spinner.tsx";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { notifier } from "../../../utils/notifier.ts";
import type { Style } from "../../types.ts";
import type { Asset, StyleGenerationParams } from "../types.ts";

export const HeaderGenerationInformation = ({ asset }: { asset: Asset }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Popover
      side="bottom"
      isOpen={isMenuOpen}
      onOpenChange={setIsMenuOpen}
      content={<HeaderGenerationInformationContent asset={asset} />}
      className="!z-50 m-200 p-200 w-[288px] !bg-surface-inverse-rest"
      modal
    >
      <BaseButton
        className={classNames(
          "px-200 max-w-full h-600 flex-row items-center justify-between gap-100 rounded-border-radius-button",
          isMenuOpen
            ? "bg-surface-inverse-active"
            : "hover:bg-surface-inverse-hover",
        )}
      >
        <Icon name="Sparkles" className="stroke-button-primary-rest" />
        {asset.content.generation_model_params?.user_prompt ? (
          <span className="flex-fill label-md-semibold text-button-primary-rest truncate">
            {asset.content.generation_model_params.user_prompt}
          </span>
        ) : (
          <span className="label-md-semibold text-inverse-disabled">
            No prompt
          </span>
        )}
        <Icon
          name="ChevronDown"
          className={classNames(
            "stroke-button-primary-rest",
            isMenuOpen && "-rotate-180",
          )}
        />
      </BaseButton>
    </Popover>
  );
};

const HeaderGenerationInformationContent = ({ asset }: { asset: Asset }) => (
  <div className="flex-col gap-150">
    <div className="flex-row items-center justify-between">
      <div className="label-md-semibold text-button-primary-rest">Prompt</div>
      {asset.content.generation_model_params?.user_prompt && (
        <BaseClickableIcon
          name="Copy"
          iconClassName="stroke-inverse-rest"
          className="h-800 w-800 rounded-100 hover:bg-surface-inverse-hover active:bg-surface-inverse-active"
          onClick={() =>
            navigator.clipboard
              .writeText(
                asset.content.generation_model_params?.user_prompt ?? "",
              )
              .then(() => notifier.success("Copied to clipboard."))
          }
        />
      )}
    </div>

    {asset.content.generation_model_params?.user_prompt ? (
      <div className="label-md-default text-inverse-primary">
        {asset.content.generation_model_params.user_prompt}
      </div>
    ) : (
      <span className="label-md-default text-inverse-disabled">No prompt</span>
    )}
    {asset.content.generation_model_params && (
      <div className="py-150 flex-row items-center gap-200">
        <div className="text-inverse-secondary body-md-default">
          Generated with
          <div className="flex-col w-full">
            {asset.content.generation_model_params.styles.map(
              (styleGenerationParams: StyleGenerationParams) => (
                <StyleInformationHeaderContent
                  key={styleGenerationParams.uuid}
                  styleGenerationParams={styleGenerationParams}
                />
              ),
            )}
          </div>
        </div>
      </div>
    )}
  </div>
);

const StyleInformationHeaderContent = ({
  styleGenerationParams,
}: {
  styleGenerationParams: StyleGenerationParams;
}) => {
  const { data: style, isLoading: isStyleLoading } = useAppQuery<Style>({
    queryKey: `styles/${styleGenerationParams.uuid}`,
  });
  return (
    <div>
      {isStyleLoading ? (
        <Spinner />
      ) : (
        style && (
          <div className="flex-row gap-100 py-100">
            {style.training_images.length > 0 && (
              <CDNImage
                className="h-400 w-400 aspect-square max-h-1600 max-w-1600"
                imageClassName="h-full w-full object-cover object-center rounded-100"
                src={style.training_images[0].url}
                srcDimension="thumbnail64"
              />
            )}
            <div className="label-md-default text-inverse-primary">
              {style.name}
            </div>
          </div>
        )
      )}
    </div>
  );
};
