// FIXME: This code has been inspired by Google Tag Manager's official documentation and adapted to be typescript
//  compatible with minimal modifications to the source code which explains the different linter ignore.
//  It can be slightly improved to be more typescript friendly.
declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer: any[];
    gtag: ((...args: any[]) => void) | undefined;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface GtagEventParams {
    [key: string]: string | number | boolean | GtagEventParams[];
  }
}

export const initGoogleAds = (googleAdsId: string) => {
  // Create script tag for gtag.js
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`;
  script.async = true;
  document.head.appendChild(script);

  // Initialize gtag configuration
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.dataLayer = window.dataLayer || [];

  window.gtag = function () {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };

  window.gtag("js", new Date());
  window.gtag("config", googleAdsId);
};

export const trackConversionEvent = (
  eventName: string,
  params: GtagEventParams = {},
) => {
  if (window.gtag) window.gtag("event", eventName, params);
};

export const pushEnhancedConversion = async (email: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.dataLayer = window.dataLayer || [];
  if (email) {
    window.dataLayer.push({
      enhanced_conversion_data: {
        sha256_email: await digestEmail(email),
      },
    });
  }
};

// XXX: from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
const digestEmail = async (email: string) => {
  const msgUint8 = new TextEncoder().encode(email.trim().toLowerCase()); // encode as (utf-8) Uint8Array
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
};
